main[data-page="home"] {
    display: flex;
    flex-direction: column;
    padding-top: 12%;
    gap: 40px;
}

.action-row {
    display: flex;
    gap: 10px;
}

.page-end {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.socials-row {
    display: flex;
    gap: 10px;
}

@media (max-width: 768px) {
    main[data-page="home"] {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}