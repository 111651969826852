#carousel {
    margin-top: 100px;
    margin-bottom: 100px;
}

#carousel__scroll {
    display: flex;
    overflow-x: scroll;
    overflow-y: visible;
    gap: 50px;
    scroll-snap-type: x mandatory;
    padding: 25px;
    height: 100%;
}

#carousel__scroll::-webkit-scrollbar {
    display: none;
}

.carousel__item {
    text-align: center;
    padding: 25px 50px;
    border-radius: 25px;
    box-shadow: 0 0 25px 0 rgb(var(--accent-rgb) / 10%);
    background-color: white;
    width: fit-content;
    flex: 0 0 auto;

    &[data-snap-point="true"] {
        scroll-snap-align: center;
    }

    &::marker {
        color: transparent;
    }

    & > label:after {
        background-color: var(--grey-800);
        display: block;
        height: 1px;
        content: "";
        margin: 20px 35%;
    }

    & > p {
        margin: 0;
    }

    & > label {
        color: var(--accent);
        font-size: 1.1rem;
        letter-spacing: 2px;
    }
}

#carousel__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carousel__controls--button {
    font-family: "Varela Round", sans-serif;
    font-size: 2rem;
    aspect-ratio: 1 / 1;
    height: fit-content;
    color: var(--accent);
    cursor: pointer;
    transition: 200ms ease-in-out;
    padding: 0 8px;
    border-radius: 5px;
}

.carousel__controls--button[data-disabled="false"]:hover {
    background-color: rgb(var(--accent-rgb) / 5%);
}

#carousel__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.carousel__pagination--button {
    aspect-ratio: 1 / 1;
    width: 10px;
    border-radius: 50px;
    cursor: pointer;
    background-color: var(--grey-600);
}

.carousel__pagination--button[data-active="true"] {
    background-color: var(--accent);
    cursor: not-allowed;
}

.carousel__controls--button[data-disabled="true"] {
    color: var(--grey-600);
    cursor: not-allowed;
}

@media (max-width: 768px) {
    #carousel {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .carousel__item {
        font-size: 0.9rem;
        padding: 25px;
    }

    .carousel__controls--button {
        display: none;
    }
}