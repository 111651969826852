nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    width: 100%;
}

#nav__content {
    border-bottom: 1px solid var(--grey-600);
    display: flex;
    padding: 15px 0;
    align-items: center;
    background-color: white;
}

#pages {
    list-style-type: none;
    display: flex;
    gap: 25px;
    margin: 0;
    padding: 0;
}

#pages > li > a {
    text-decoration: none;
    color: var(--text-strong);
}

#pages > li > a:hover {
    color: var(--accent);
}

#logo {
    width: var(--padding-x);
    text-align: center;
}

.components {
    display: flex;
    gap: 10px;
    margin-left: auto;
    margin-right: 20px;
}

.scroll-progress-bar {
    width: 100%;
    height: 5px;
    background-color: var(--grey-800);
}

.scroll-progress-bar > span {
    display: block;
    height: 100%;
    background-color: var(--accent);
    width: 0;
}

#nav__mobile-menu-icon {
    display: none;
    width: 18px;
    aspect-ratio: 1 / 1;
    position: relative;
    margin-right: 40px;
    margin-left: 15px;

    /* Animation Durations */
    --d: 100ms;
    --d-opacity: 50ms;
    --d-rotate: 150ms;

    /* Distance between gaps */
    --gap: 7px;

    & > .bar {
        height: 3px;
        width: 100%;
        background-color: var(--accent);
        border-radius: 10px;
        position: absolute;

        &:nth-of-type(1) {
            top: 0;
            transition: top var(--d) ease var(--d), transform var(--d) ease-out var(--d-opacity);
        }

        &:nth-of-type(2) {
            top: var(--gap);
            transition: ease var(--d) var(--d);
        }

        &:nth-of-type(3) {
            top: calc(var(--gap) * 2);
            transition: top var(--d) ease var(--d), transform var(--d) ease-out var(--d-opacity);
        }
    }

    &[data-expanded="true"] > .bar {
        top: var(--gap);

        &:nth-of-type(1) {
            transform: rotate(45deg);
            transition: top var(--d) ease var(--d-opacity), transform var(--d) ease-out var(--d-rotate);
        }

        &:nth-of-type(2) {
            opacity: 0;
        }

        &:nth-of-type(3) {
            transform: rotate(-45deg);
            transition: top var(--d) ease var(--d-opacity), transform var(--d) ease-out var(--d-rotate);
        }
    }
}

@media (max-width: 768px) {
    #nav__content {
        margin: 20px 15px 0 15px;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 20px 0 rgb(var(--accent-rgb) / 20%);
        padding: 10px 15px;
        justify-content: space-between;
    }

    .switch {
        display: none;
    }

    .components {
        margin: 0;
    }

    #nav__mobile-menu-container {
        pointer-events: none;
        position: absolute;
        margin: 0 var(--padding-x);
        border-radius: 16px;
        overflow: hidden;
        top: 100px;
        left: 0;
        right: 0;
    }

    #nav__mobile-menu-container[data-expanded="true"] > .content {
		transform: translateY(0);
    }

    #nav__mobile-menu-container > .content {
        transition: 250ms;
		transform: translateY(-100%);
        display: flex;
        flex-direction: column;
        gap: 12px;
		background-color: var(--accent);
		padding-left: 24px;
		border-radius: 16px;
		box-shadow: inset 0 0 0 1px var(--accent-dim);
    }

    #pages {
        padding: 16px 0;
        pointer-events: auto;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    #pages > li:not(:last-child)::after {
        display: block;
        background-color: rgb(255 255 255 / 20%);
        width: 100%;
        border-radius: 50px;
        margin-top: 15px;
        height: 1px;
        content: "";
    }

    #pages > li > a:hover {
        /* Do not change color on hover */
        color: inherit;
    }

    #pages > li > a {
        display: block;
        color: white;
        width: 100%;
    }

    #pages > li > a:hover {
        color: white;
    }

    #nav__mobile-menu-icon {
        display: block;
    }

    .scroll-progress-bar {
        display: none;
    }
}
