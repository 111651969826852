:root {
    --padding: 2px;
}

.switch {
    position: relative;
}

.switch > span {
    position: absolute;
    top: 13px;
    pointer-events: none;
    font-size: 12px;
    width: 50%;
    text-align: center;
}

#locale-switch:checked ~ .off {
    color: var(--grey-800);
}

#locale-switch:checked ~ .on {
    color: var(--accent);
}

.switch > span.on {
    left: 0;
    padding-left: var(--padding);
    color: var(--grey-800);
}

.switch > span.off {
    right: 0;
    padding-right: 4px;
    color: var(--accent);
}

#locale-switch {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
}

#locale-switch + label {
    display: block;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#locale-switch + label {
    padding: var(--padding);
    width: 97px;
    height: 35px;
    background-color: var(--grey-800);
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
}

#locale-switch + label:before, #locale-switch + label:after {
    display: block;
    position: absolute;
    content: "";
}

#locale-switch + label:before {
    top: var(--padding);
    left: var(--padding);
    bottom: var(--padding);
    right: var(--padding);
    background-color: var(--grey-800);
    -moz-border-radius: 60px;
    border-radius: 60px;
}

#locale-switch + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 48px;
    background-color: var(--accent);
    -webkit-border-radius: 52px;
    -moz-border-radius: 52px;
    border-radius: 52px;
    -webkit-transition: margin 0.2s;
    -moz-transition: margin 0.2s;
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
}

#locale-switch:hover + label:after {
    background-color: var(--accent-dim);
}

#locale-switch:checked + label:after {
    margin-left: 44px;
}

#locale-dropdown {
    /* Subtract left margin */
    width: calc(100% - 24px);
    border-radius: 8px;
    border: 1px solid white;
    background-color: rgba(255 255 255 / 10%);
    color: white;
    font-size: 1rem;
    pointer-events: auto;
    padding: 8px 12px;
    margin-bottom: 24px;
    box-sizing: border-box;
}

#locale-dropdown:focus {
    outline: none;
}