.dropdown__toggle {
    all: unset;
    cursor: pointer;
    width: calc(100% - 32px);
    padding: 24px 16px;
    display: flex;
    gap: 16px;
}

.dropdown__toggle:hover {
    color: var(--accent);
}

.dropdown {
    width: 100%;
}

.dropdown__toggle > img {
    width: 16px;
    height: 16px;
}

.dropdown:not(:last-child) {
    border-bottom: 1px solid rgb(var(--accent-rgb) / 20%);
}

.dropdown__content {
    padding: 24px 16px;
    border-top: 1px solid rgb(var(--accent-rgb) / 20%);
}

@media (max-width: 768px) {
    .dropdown__toggle {
        font-size: 14px;
    }
}