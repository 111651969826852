main[data-page="functional-tests"] h1 {
    color: var(--accent) !important;
    text-align: center;
    font-size: 1.8rem !important;
    margin: 10px 0;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.details__item {
    display: flex;
    flex-direction: column;
    text-align: center;
}

#functional-testing-example {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.1rem;
    gap: 35px;
    color: black;
}

main[data-page="functional-tests"] {
    display: flex;
    flex-direction: column;
    gap: 120px;
}

@media (max-width: 768px) {
    #functional-testing-example > img {
        width: 90%;
    }

    main[data-page="functional-tests"] {
        gap: 50px;

        & .purchase-row {
            flex-direction: column-reverse;
            gap: 25px;

            & .button {
                border-radius: 50px;
                padding: 12px 20px;
            }

            & .price {
                font-size: 1.5rem;
                font-weight: bold;
            }
        }
    }

    .details {
        gap: 20px;
    }
}