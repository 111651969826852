.social-media {
    display: flex;
    gap: 8px;
    border-radius: 50px;
    border: 1px solid var(--accent);
    padding: 10px 14px;
    align-items: center;
    font-size: 0.8rem;
    transition: 200ms;

    & > img {
        aspect-ratio: 1 / 1;
        height: 16px;
    }
}

.social-media:hover {
    background-color: rgb(var(--accent-rgb) / 10%);
    text-decoration: none;
}