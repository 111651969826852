main {
    padding-top: 15%;
    padding-left: var(--padding-x);
    padding-right: var(--padding-x);
}

.button {
    background-color: var(--button-bg, var(--accent));
    color: var(--button-text, white);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    outline: none;
    padding: 10px 16px;
    transition: 0.2s;
    font-size: 0.9rem;
}

.button:hover {
    background-color: var(--button-hover-bg, var(--accent-dim));
}

.button.secondary {
    --button-bg: var(--grey-800);
    --button-text: var(--grey-200);
    --button-hover-bg: var(--grey-600);
}

header.decorative-line:before {
    background-color: var(--accent);
    display: block;
    width: 15%;
    height: 3px;
    margin-bottom: 25px;
    content: "";
}

.purchase-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 18px;
    margin-top: 30px;
}

.purchase-row > .price {
    color: var(--accent);
    font-weight: bold;
    margin: 0;
}

.purchase-row > a {
    border-radius: 6px;
    padding: 8px 12px;
    height: fit-content;
}

@media (max-width: 768px) {
    header.decorative-line:before {
        display: none;
    }

    main {
        padding-left: 40px !important;
        padding-right: 40px !important;
        padding-top: 30% !important;
    }

    .purchase-row {
        flex-direction: column;
        width: 100%;
    }

    blockquote {
        border: 2px solid var(--accent);
        border-radius: 12px;
        padding: 20px !important;
    }
}