#commitments {
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-size: 0.9rem;
}

.commitment {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;

    & > img {
        width: 125px;
    }

    & > label {
        color: var(--text-strong);
        margin-top: 25px;
    }
}

@media (max-width: 768px) {
    #commitments {
        flex-direction: column;
        font-size: 1rem;
        gap: 50px;
        padding-left: 40px;
        padding-right: 40px;
    }
}