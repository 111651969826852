:root {
    --padding-x: 8%;

    --accent: #4CA48A;
    --accent-rgb: 76 164 138;
    --accent-dim: #3E8A6D;
    --accent-dark: #356F5E;
    --grey-850: #FAFAFA;
    --grey-800: #F3F3F3;
    --grey-600: #EEEEEE;
    --grey-200: #5F5F5F;

    --text-strong: #1B1B1B;
    --text-primary: var(--grey-200);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text-primary);
}

h1, h2, h3, h4, h5, h6 {
    color: var(--text-strong);
}

h1 {
    font-size: 2rem;
}

p {
    line-height: 25px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
    border: 1px solid var(--grey-800);
    width: 10%;
}

a {
    color: var(--accent);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

blockquote {
    border-left: 2px solid var(--accent);
    margin: 1.5em 0;
    padding: 5px 16px;
    quotes: "\201C" "\201D" "\2018" "\2019";
}

blockquote > p {
    display: inline;
    margin: 0;
}

@media (max-width: 768px) {
    html, body {
        overflow-x: hidden;
    }
}