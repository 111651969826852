main[data-page="packages"] {
    padding-top: 10%;

    & h2 {
        color: var(--accent);
        margin-top: 50px;
    }

    & > h1 {
        color: var(--accent);
        margin: 0 auto;
        width: fit-content;
    }

    & h2:not(:first-child)::before {
        content: "";
        display: block;
        width: 60%;
        height: 1px;
        background-color: var(--grey-800);
        margin: 0 auto 50px auto;
    }

    & .purchase-row {
        gap: 25px;
        box-shadow: 0 0 15px 0 var(--grey-800);
        padding: 8px 20px 8px 28px;
        border-radius: 50px;
        margin-top: 0;
    }

    & .purchase-row > .button-row {
        display: flex;
        gap: 10px;
    }

    & .button {
        padding: 12px 20px;
        border-radius: 50px;
    }
}

#packages {
    display: flex;
    flex-direction: column-reverse;
}

.package {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    margin-bottom: 80px;
}

.package__description, .package__overview, .package__price {
    text-align: center;
}

.package__overview {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.package__banner {
    border-radius: 12px 12px 0 0;
    width: 100%;
}

.package__duration {
    box-sizing: border-box;
    background-color: var(--grey-800);
    border-radius: 0 0 12px 12px;
    width: 100%;
    padding: 12px;
}

.package__price {
    font-weight: bold;
    color: var(--accent);
}

table {
    border-radius: 12px;
    border: 1px solid var(--grey-800);
    margin-top: 35px;
    margin-bottom: 35px;
    border-spacing: 0;
    overflow: hidden;

    & th {
        background-color: var(--accent);
        color: white;
    }

    & p {
        margin: 12px 16px;
    }

    & tr:nth-child(odd) {
        background-color: var(--grey-800);
    }
}

.help-text {
    text-align: center;
    margin: 60px 0;
}

@media (max-width: 768px) {
    main[data-page="packages"] {
        & .purchase-row {
            flex-direction: column;
            gap: 10px;
            box-shadow: none;
        }
    }

    .package__overview {
        width: 100%;
    }

    .package {
        gap: 0;
    }

    .package__price {
        font-size: 2rem;
        margin-top: 0;
    }
}