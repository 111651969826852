main[data-page="consultations"] {
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

#consultations__hero {
    display: flex;
    gap: 50px;
    align-items: center;

    & > img {
        width: 20%;
        border-radius: 8px;
    }
}

.consultations__hero--overview > h1 {
    color: var(--accent);
}

#consultations__list {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.consultation {
    & > h2 {
        color: var(--accent);
        margin-top: 0;
    }

    &:not(:last-child)::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--grey-800);
        margin-top: 50px;
    }
}

#consultations__cancellation-policy {
    & h3 {
        color: var(--accent);
    }

    & p {
        margin: 0;
    }
}

.consultation__duration {
    font-weight: bold;
}

@media (max-width: 768px) {
    main[data-page="consultations"] {
        text-align: center;

        & ul {
            list-style: none;
            padding: 0;
        }

        & li:not(:last-child) {
            border-bottom: 1px solid var(--grey-800);
            margin-bottom: 15px;
            padding-bottom: 15px;
        }
    }

    #consultations__hero {
        flex-direction: column;
        gap: 20px;

        & > img {
            width: 100%;
        }
    }
}