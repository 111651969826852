.dna-test {
    display: flex;
    gap: 50px;
}

.dna-test__overview > h2 {
    margin-top: 0;
}

#dna-tests, #dna-tests__products {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.dna-test > img {
    object-fit: contain;
    aspect-ratio: 1 / 1;
    width: 200px;
}

@media (min-width: 768px) {
    .dna-test:not(:last-child) > .dna-test__overview::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--grey-800);
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    #dna-tests__products {
        gap: 60px;
    }

    .dna-test .purchase-row > .price {
        font-size: 1.5rem;
    }

    .dna-test {
        flex-direction: column;
        gap: 0;
        align-items: center;
        text-align: center;
    }

    .dna-test > img {
        width: 80%;
    }
}