#faq {
    display: flex;
    flex-direction: column;
    padding: 0 80px;
    gap: 15px;
}

.faq__option {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;
}

.faq__option > * {
    padding: 12px 16px;
}

.faq__option > label {
    background-color: var(--grey-600);
    color: black;
}

.faq__option p {
    background-color: var(--grey-850);
    transition: height 200ms;
    margin: 0 !important;
}

@media (max-width: 768px) {
    #faq {
        padding: 0;
        text-align: center;
    }
}