.newsletter {
    display: flex;
    width: 100%;
    margin-top: 80px;
}

.newsletter__cover {
    width: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.newsletter > form {
    display: flex;
    flex-direction: column;
    background-color: var(--accent);
    outline: 2px solid white;
    outline-offset: -20px;
    padding: 50px;
    height: fit-content;
    flex-grow: 1;
    flex-basis: 0;
    width: 50%;
}

.newsletter > form > header {
    display: flex;
    flex-direction: column;
    text-align: center;

    & > h2 {
        color: white;
        margin: 5px;
    }

    & > p {
        opacity: 0.8;
        color: white;
        margin: 5px;
    }
}

.newsletter > form > fieldset {
    display: flex;
    flex-direction: column;
    border: none;

    & > p {
        display: flex;
        flex-direction: column;
        gap: 5px;
        color: white;

        & > input {
            padding: 12px 16px;
            border-radius: 5px;
            border: none;
        }

        & > input::placeholder {
            color: var(--grey-200);
            font-size: 0.8rem;
        }

        & > input:active, & > input:focus {
            outline: 3px solid var(--accent-dim);
        }
    }

    & > button {
        border: none;
        background-color: var(--accent-dark);
        color: white;
        border-radius: 5px;
        padding: 12px 0;
        cursor: pointer;
        margin-top: 10px;
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .newsletter {
        margin-top: 40px;
        flex-direction: column;
    }

    .newsletter > form {
        width: 100%;
        box-sizing: border-box;
    }

    .newsletter__cover {
        height: 400px;
        width: 100%;
    }
}