main[data-page="approach"] {
    padding-left: 20%;
    padding-right: 20%;
}

main[data-page="approach"] h1 {
    color: var(--accent);
    margin-top: 50px;
    font-size: 1.8rem;
}

.approach__banner {
    width: 100%;
    height: 250px;
    border-radius: 12px;
    object-fit: cover;
    object-position: 0 -100px;
}

@media (max-width: 768px) {
    main[data-page="approach"] {
        padding-top: 20% !important;
        text-align: center;
    }

    .approach__banner {
        height: 110px;
        margin-top: 50px;
        object-position: 0 -40px;
    }
}