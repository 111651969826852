.hero {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

.hero > img {
    border-radius: 8px;
    width: 30%;
    margin-top: 8vh;
}

.hero > .overview h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.75rem;
}

.hero > .overview {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 60%;
}

.hero > .overview > .image-row {
    display: flex;
    gap: 20px;
}

.hero > .overview > .image-row > img {
    height: 80px;
    object-fit: contain;
}

.hero > .overview > .top {
    width: 85%;
    display: flex;
    flex-direction: column;
}

.hero > .overview > .top p {
    margin-bottom: 0;
}

#aid-list > label:after {
    background-color: var(--grey-600);
    display: block;
    margin-top: 10px;
    height: 1px;
    width: 100%;
    content: "";
    margin-bottom: 20px;
}

#aid-list > ul > li {
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--text-strong);
    margin-bottom: 8px;
}

#aid-list > ul > li > img {
    display: inline-block;
    width: 15px;
    aspect-ratio: 1 / 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    content: "";
}

#aid-list > ul {
    columns: 3;
    margin: 0;
    padding: 0;
}

.checkmark {
    position: relative;
    width: 7.5px;
    height: 12px;
    margin-right: 5px;
}

.checkmark::after, .checkmark::before {
    content: "";
    bottom: 0;
    position: absolute;
    display: block;
    width: 2px;
    background-color: var(--accent);
    border-radius: 20px;
}

.checkmark::after {
    right: 0;
    height: 100%;
    transform: rotate(45deg);
}

.checkmark::before {
    left: 0;
    height: 60%;
    transform: rotate(-45deg);
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
        gap: 40px;
    }

    .hero > .overview {
        width: 100%;
        align-items: center;
    }

    .hero > img {
        width: 80%;
        margin-top: 0;
    }

    .hero > .overview h1 {
        font-size: 1.6rem;
    }

    .hero > .overview header:before {
        display: none;
    }

    #aid-list > ul {
        columns: 1;
    }
}