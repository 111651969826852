#about__content > header {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#about__content > header > * {
    margin: 0;
}

#about__content > header > h1 {
    color: var(--accent);
}

#about__description {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#about__cover {
    border-radius: 8px;
    width: 250px;
    height: 100%;
}

main[data-page="about"] {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 100px;

    & h2 {
        color: var(--accent);
        margin-top: 60px;
    }

    & li p {
        margin: 0;
    }
}

#certificates {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.certificate {
    width: 45%;
    object-fit: contain;
}

@media (min-width: 768px) {
    #about__cover {
        position: sticky;
        top: 200px;
    }
}

@media (max-width: 768px) {
    main[data-page="about"] {
        text-align: center;
        flex-direction: column-reverse;
        align-items: center;
        gap: 50px;

        & ul {
            list-style: none;
            padding: 0;
        }

        & ul > li:not(:last-child)::after {
            content: "";
            width: 20%;
            background-color: var(--grey-800);
            height: 1px;
            display: block;
            margin: 10px auto;
        }
    }

    #about__cover {
        width: 60%;
    }
}