main[data-page="programmes"] {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 8%;

    & p {
        margin: 0.5rem 0;
    }

    & > h1 {
        color: var(--accent);
        text-align: center;
        margin-bottom: 50px;
    }

    & h2 {
        margin-top: 0;
        margin-bottom: 35px;
        color: var(--accent);
    }
}

.programme__cover-images > img {
    border-radius: 8px;
    width: 30%;
    display: block;
    margin: 0 auto 25px auto;
}

.programme__cover-images {
    display: flex;
    justify-content: space-between;
}

.programme > h2 {
    text-align: center;
}

@media (max-width: 768px) {
    main[data-page="programmes"] {
        text-align: center;

        & ul {
            list-style: none;
            padding: 0;
        }

        & li:not(:last-child) {
            border-bottom: 1px solid var(--grey-800);
            margin-bottom: 15px;
            padding-bottom: 15px;
        }
    }

    .programme__cover-images > img {
        width: 100%;
    }
}